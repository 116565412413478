/*eslint-disable */
import {
  mapGetters
} from 'vuex'; 
export default {
  name: 'global-notification-list',
  components: {},
  props: [],
  data () {
    return {
      notificationListData:null,
      notificationListDataResults:[]
    }
  },
  computed: {
    ...mapGetters([
      'getNotificationGetters'
    ]),
    getNotificationListDataAction(){
      return this.$store.getters.getNotificationGetters
    },
  },
  mounted () {
  },
  methods: {

  },
  watch:{
    getNotificationListDataAction(newData, oldData){
      this.notificationListData = newData.data
      this.notificationListDataResults = this.notificationListData.results
    }
  }
}