/*eslint-disable */

import beforeBookingMessageList from '@/components/private/before-booking-message-list';
import {bus} from '@/main';
import countries from '@/services/country.json'
import doctorInMailPopup from '@/components/private/doctor-in-mail-popup';
import makeUnfavouriteDoctor from '@/components/private/make-unfavourite-doctor';

export default {
  name: 'doctor-list-for-dashboard',
  components: {
    'doctor-mail-popup' : doctorInMailPopup,
    'message-list':beforeBookingMessageList,
    'unfavourite-doctor-list' : makeUnfavouriteDoctor
  },
  props: [],
  data () {
    return {
      favouriteAppointmentListData:null,
      favouriteAppointmentListResults:[],
      countryList:[],
      directSmsForPopup:false,
      beforeBookingMessageList:null,
      beforeBookingMessageListState:false,
      unFavDoctorPopupState:false,
      doctorId:''
    }
  },
  computed: {

  },
  mounted () {
    this.getFavDoctorsListMehtod();
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key].toLowerCase(),
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
    bus.$on('userUpdateStatus', (data) => {
      let user = this.favouriteAppointmentListResults.find(item => item.id == data.userID);
      if(user) {
        user.is_online = data.is_online;
      }
    });
    bus.$on('directSmsForPopupBus', (data) => {
      this.directSmsForPopup = data
      this.getFavDoctorsListMehtod();
    })
    bus.$on('beforeBookingMessageListStateBus', (data) => {
      this.beforeBookingMessageListState = data
    })
    bus.$on('unFavDoctorPopupStateBus', (data) => {
      this.unFavDoctorPopupState = data
      this.getFavDoctorsListMehtod();
    });
  },
  methods: {
    getFavDoctorsListMehtod(){
      this.$store.dispatch('getDoctorFavouriteListFun').then((res) => {
        this.favouriteAppointmentListData = res.data.data
        this.favouriteAppointmentListResults = this.favouriteAppointmentListData.results || []
      })
    },
    unFavouriteDoctorMethod(id){
      document.body.classList.add('popup_open_body')
      this.doctorId = id
      this.unFavDoctorPopupState = true
    },
    beforeBookingMessageListMethod(data){
      document.body.classList.add('popup_open_body')
      this.beforeBookingMessageList = data
      this.beforeBookingMessageListState = true
    },
    directSmsForPopupOpenMethod(data){
      this.userInfo = data
      this.directSmsForPopup = true
      document.body.classList.add('popup_open_body')
    },
    getCountryFlag: function(countryCode, type) {
      if (countryCode == null) {
        countryCode = ''
      }
      let countryName = countryCode.toLowerCase()
      let country = this.countryList.filter(item => (item.value) == (countryName) || (item.name) == (countryName));
      if (country && country.length > 0) {
        country = country[0]
        if (type == 'flag') {
          country = `https://567c9b4f06e54c9c9229fe11191a6432.s3.ca-central-1.amazonaws.com/media/shiny/${country.value}.png`
        } else {
          country = country.name
        }
      } else {
        country = ''
      }
      return country;
    },
  }
}


