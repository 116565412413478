import { render, staticRenderFns } from "./add-about-of-user-info.html?vue&type=template&id=5b42262a&scoped=true&external"
import script from "./add-about-of-user-info.js?vue&type=script&lang=js&external"
export * from "./add-about-of-user-info.js?vue&type=script&lang=js&external"
import style0 from "./add-about-of-user-info.scss?vue&type=style&index=0&id=5b42262a&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b42262a",
  null
  
)

export default component.exports